<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">数据展示</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  <!--  库存数据展示-->
    <div style="width: 95%;min-height: 80vh;background-color: #FFFFFF;margin: auto">
      <div style="margin-left: 5%;padding-top: 2%">
        <el-button type="primary" @click="gotoDataShow">库存数据展示</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";

export default {
  name: "material_data",
  components: {Header},
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  data(){
    return{
      material_data: []
    }
  },
  created() {

  },
  methods:{
    gotoDataShow(){
      this.axios.post('/materialInfoemation/getMaterialNumShow', (response) => {
        this.material_data = response.obj;
      },{
        bdst: this.GET_BDST
      });
      sessionStorage.setItem("material_data", JSON.stringify(this.material_data))
      let pathInfo = this.$router.resolve({
        path:'/material_num_show',
        query:{

        }
      })
      window.open(pathInfo.href, '_blank');
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>